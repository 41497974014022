@font-face {
  font-family: "Berkeley Mono";
  src: url('fonts/BerkeleyMono-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Berkeley Mono";
  src: url('fonts/BerkeleyMono-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Berkeley Mono";
  src: url('fonts/BerkeleyMono-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Berkeley Mono";
  src: url('fonts/BerkeleyMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Berkeley Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
